import { isVaillant } from '../helpers/realm';

const COOKIE_CONFIG_VAILLANT = {
  src: 'https://cdn.consentmanager.net/delivery/autoblocking/5bf1fdb06190d.js',
  host: 'c.delivery.consentmanager.net'
};

const COOKIE_CONFIG_BULEX = {
  src: 'https://cdn.consentmanager.net/delivery/autoblocking/223915f181240.js',
  host: 'b.delivery.consentmanager.net'
};

const COOKIE_CONFIG = {
  cdn: 'cdn.consentmanager.net',
  codesrc: '1',
  ab: '1',
  type: 'text/javascript',
  ...(isVaillant ? COOKIE_CONFIG_VAILLANT : COOKIE_CONFIG_BULEX)
};

const script = document.createElement('script');
script.src = COOKIE_CONFIG.src;
script.type = COOKIE_CONFIG.type;
script.dataset.cmpAb = COOKIE_CONFIG.ab;
script.dataset.cmpHost = COOKIE_CONFIG.host;
script.dataset.cmpCdn = COOKIE_CONFIG.cdn;
script.dataset.cmpCodesrc = COOKIE_CONFIG.codesrc;

export default script;
