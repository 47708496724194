import VaillantLogo from '../../assets/vaillant-logo.png';
import VaillantLogoSmall from '../../assets/vaillant-logo-small.png';
import BulexLogo from '../../assets/bulex-logo.png';
import BulexLogoSmall from '../../assets/bulex-logo-small.png';
import { isVaillant } from '../../helpers/realm';

export function getLogo(isSmallScreen: boolean) {
  if (isVaillant) {
    return isSmallScreen ? VaillantLogoSmall : VaillantLogo;
  }
  return isSmallScreen ? BulexLogoSmall : BulexLogo;
}
