import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function UserWebRoles({ data }: { data: string[] }) {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" mb={2}>
        My web roles
      </Typography>
      {data.length ? (
        data.map((name) => (
          <Typography
            gutterBottom
            variant="body1"
            key={name}
            sx={{ wordWrap: 'break-word' }}
          >
            {name}
          </Typography>
        ))
      ) : (
        <Typography gutterBottom variant="body1">
          There are no web roles assigned to the user
        </Typography>
      )}
    </Paper>
  );
}
