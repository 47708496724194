import AuthContext from "../../auth/authContext";
import { useContext } from "react";

export default function Popup() {
    const { status, keycloak } = useContext(AuthContext);
    keycloak.onAuthSuccess = () => {
        window.opener.location.reload();
        window.close()
    }
    if (status.type === 'initialized') {
        keycloak.login()
    }
    return null
}