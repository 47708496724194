import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProductCard from '../../components/ProductCard/ProductCard';
import PRODUCTS from '../../mocks/ProductMocks';

export default function Home() {
  return (
    <>
      <Box sx={{ m: 1, mt: 3, mb: 3 }}>
        <Typography gutterBottom variant="h4">
          Vaillant heaters - warmth and comfort for your home.
        </Typography>
        <Typography gutterBottom variant="body1">
          More than 140 years have passed since Johann Vaillant founded his
          master-craftsman installation business in a small half-timbered house
          in Remscheid/Germany. What began in 1874 is now a company with more
          than 12,000 employees and a globally leading provider of sustainable
          and responsible system solutions and services for heating cooling and
          hot water.
        </Typography>
      </Box>
      <Grid container>
        {PRODUCTS.map(({ title, description, image }, index) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={title + index}>
              <ProductCard
                title={title}
                description={description}
                image={image}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
