import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

export default function NotFound() {
  return (
    <Box mt={12}>
      <Stack direction="column" textAlign="center">
        <ErrorIcon
          color="error"
          fontSize="large"
          sx={{ alignSelf: 'center' }}
        />
        <Typography variant="h4" mt={2} mb={1}>
          Page not found
        </Typography>
        <Typography variant="h5">
          Go back to{' '}
          <Link to="/" component={RouterLink}>
            home page
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
