import { ProductCardProps } from '../components/ProductCard/ProductCard.types';
import BulexImage from '../assets/bulex-image-1.jpg';
import BulexImage2 from '../assets/bulex-image-2.jpg';
import VaillantImage from '../assets/vaillant-image-1.jpg';
import VaillantImage2 from '../assets/vaillant-image-2.jpg';

import { isVaillant } from '../helpers/realm';

const image1 = isVaillant ? VaillantImage : BulexImage;
const image2 = isVaillant ? VaillantImage2 : BulexImage2;

const PRODUCTS: ProductCardProps[] = [
  {
    title: 'Boilers',
    description:
      'At Vaillant, we offer different types of gas boilers to suit every living space. There are three main types of boilers available, combi, system and regular (sometimes called open-vent and conventional boilers). ',
    image: image1,
  },
  {
    title: 'Heat Pumps',
    description:
      'Heat pumps work by extracting thermal energy and converting it into heating and hot water to your home. This can help to reduce your home’s carbon footprint by replacing the reliance on fossil fuels with a more renewable energy source. ',
    image: image2,
  },
  {
    title: 'Controls',
    description:
      'We produce a range of thermostats and controls to help you manage your heating and hot water needs. This technology allows you to be fully in charge of your heating, helping you to save money on energy bills, as well as make your home more energy-efficient.',
    image: image1,
  },
  {
    title: 'Cylinders',
    description:
      'Vaillant hot water cylinders are expertly designed and engineered to work in perfect harmony with your Vaillant heating system, whether they are working alongside a heat pump or boiler. ',
    image: image2,
  },
];

export default PRODUCTS;
