import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

import AuthContext from '../../auth/authContext';
import styles from './Header.module.css';
import { getLogo } from './helpers';
import { isVaillant } from '../../helpers/realm';

export default function Header() {
  const { status, keycloak } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row">
          <Link
            to="/"
            component={NavLink}
            underline="none"
            className={styles.link}
          >
            { isVaillant ? 'B2B' : 'B2C'}
          </Link>
          <Link
            to="/partnernet"
            component={NavLink}
            underline="none"
            className={styles.link}
          >
            PartnerNET
          </Link>
          <Link
            to="/campaign"
            component={NavLink}
            underline="none"
            className={styles.link}
          >
            Campaign
          </Link>
        </Stack>
        <Stack direction="row">
        {keycloak.authenticated ? null : (<Button
            onClick={() => {
              keycloak.authenticated ? keycloak.logout() :
              window.open('/popup', 'newwindow', 'width=540, height=700')}
            }
            className={styles.button}
          >
            Login (New window)
          </Button>)}
        {status.type === 'loading' ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            className={styles.button}
            onClick={() => {
              keycloak.authenticated
                ? keycloak.logout({
                    redirectUri: window.location.origin,
                  })
                : keycloak.login();
            }}
          >
            {keycloak.authenticated ? 'Logout' : 'Login'}
          </Button>
        )}
        </Stack>
      </Container>
      <Box
        component="div"
        sx={{ backgroundColor: '#ffffff', pt: 1.5, pb: 1.5 }}
      >
        <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" sx={{ p: 1, mr: 3 }}>
            <Link to="/" component={NavLink}>
              <img
                style={{ maxHeight: 36 }}
                src={getLogo(isSmallScreen)}
                alt="Vaillant Logo"
              />
            </Link>
          </Box>
          {['Products', 'Advice & Knowledge', 'Service'].map((name) => (
            <Button
              size="large"
              sx={{
                color: '#666666',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              key={name}
            >
              {name}
            </Button>
          ))}
        </Container>
      </Box>
    </>
  );
}
