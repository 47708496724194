import Keycloak from 'keycloak-js';
import { isVaillant } from '../helpers/realm';

const keycloakConfigVaillant = {
  url: 'https://identity-qa.vaillant-group.com/auth',
  realm: 'vaillant-germany',
  clientId: 'playground-spa',
};

const keycloakConfigBulex = {
  url: 'https://identity-qa.vaillant-group.com/auth',
  realm: 'bulex-b2c',
  clientId: 'playground-b2c-spa',
};

const keycloak = new Keycloak(
  isVaillant ? keycloakConfigVaillant : keycloakConfigBulex
);

export default keycloak;
