import { CompanyType, UserInfoType } from '../../auth/types';
import { UserDataEntry } from '../../components/UserInformation/UserInformation.types';

const formatAddress = (company: CompanyType | undefined) => {
  if (!company?.address) {
    return undefined;
  }
  return `${company.address.street}, ${company.address.postalCode} ${company.address.city}, ${company.address.country}`;
};

export const getCompanyData = (
  userInfo: UserInfoType | undefined
): UserDataEntry[] => {
  const { company, salesforceAccountId, salesforceLoyaltyId } = userInfo || {};

  return [
    {
      key: 'Company name',
      value: company?.name,
    },
    {
      key: 'Salesforce account id',
      value: salesforceAccountId,
    },
    {
      key: 'Customer number	',
      value: company?.customerNumber,
    },
    {
      key: 'Address',
      value: formatAddress(company),
    },
    {
      key: 'Phone',
      value: company?.contact.phone,
      type: 'phone',
    },
    {
      key: 'Fax',
      value: company?.contact.fax,
    },
    {
      key: 'E-Mail',
      value: company?.contact.email,
      type: 'email',
    },
    {
      key: 'Salesforce loyalty id',
      value: salesforceLoyaltyId,
    },
  ];
};

export const getContactData = (
  userInfo: UserInfoType | undefined
): UserDataEntry[] => {
  const { name, email, salesforceContactId } = userInfo || {};

  return [
    { key: 'Name', value: name },
    { key: 'Salesforce contact id', value: salesforceContactId },
    { key: 'E-mail', value: email, type: 'email' },
  ];
};
