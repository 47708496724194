import { createTheme } from '@mui/material/styles';

import { isVaillant } from '../helpers/realm';

const theme = createTheme({
  palette: {
    primary: {
      main: isVaillant ? '#00917e' : '#c03',
    },
    background: {
      default: '#EEEEEE',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
