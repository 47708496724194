import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { ValueProps } from './Value.types';

function Value({ value = '-', type }: ValueProps) {
  if ((type === 'phone' || type === 'email') && value !== '-') {
    return (
      <Link
        href={`${type === 'phone' ? 'tel:' : 'mailto:'}${value}`}
        underline="none"
        sx={{ wordWrap: 'break-word' }}
      >
        {value}
      </Link>
    );
  } else {
    return (
      <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
        {value}
      </Typography>
    );
  }
}

export default Value;
