import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import UserInformationProps from './UserInformation.types';
import Value from './Value/Value';

export default function UserInformation({ title, data }: UserInformationProps) {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" mb={2}>
        {title}
      </Typography>
      <Grid container spacing={1}>
        {data.map(({ key, value, type }) => (
          <React.Fragment key={key}>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {key}
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Value value={value} type={type} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
}
