import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getCompanyData, getContactData } from './helpers';
import ExternalCardLinkProps from '../../components/ExternalCardLink/ExternalCardLink.types';
import ExternalCardLink from '../../components/ExternalCardLink/ExternalCardLink';
import UserInformation from '../../components/UserInformation/UserInformation';
import UserWebRoles from '../../components/UserWebroles/UserWebroles';
import AuthContext from '../../auth/authContext';

export default function PartnerNet() {
  const { keycloak, status } = useContext(AuthContext);

  if (status.type !== 'authorized')
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    );

  const LINKS: ExternalCardLinkProps[] = [
    {
      title: 'External application',
      description:
        'Opens an external application, where you get automatically logged in via SSO',
      href: 'https://vaillant-germany-sso-idm-demo.dsp.vaillant-group.com/internal',
    },
    {
      title: 'Change my password',
      description:
        'If you want to change your password, this is the place to go!',
      href: keycloak.createLoginUrl({ action: 'UPDATE_PASSWORD' }),
    },
    {
      title: 'Change my email',
      description: 'If you want to change your email, this is the place to go!',
      href: keycloak.createLoginUrl({ action: 'UPDATE_EMAIL' }),
    },
  ];

  const companyData = getCompanyData(keycloak.userInfo);
  const contactData = getContactData(keycloak.userInfo);
  const webRoles = keycloak.userInfo?.realm_access?.roles || [];

  return (
    <Grid container spacing={3} mt={0}>
      <Grid item md={9} xs={12}>
        <Stack spacing={3}>
          <UserInformation title="My company data" data={companyData} />
          <UserInformation title="My contact data" data={contactData} />
          <UserWebRoles data={webRoles} />
        </Stack>
      </Grid>
      <Grid item md={3} xs={12}>
        <Stack spacing={3}>
          {LINKS.map(({ title, description, href }) => (
            <ExternalCardLink
              title={title}
              description={description}
              href={href}
              key={title}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
