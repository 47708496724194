import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ProductCardProps } from './ProductCard.types';

export default function ProductCard({
  title,
  description,
  image,
}: ProductCardProps) {
  return (
    <Box sx={{ m: 1 }}>
      <Card
        sx={{
          minHeight: '430px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <CardMedia sx={{ height: 140 }} image={image} title={title} />
          <CardContent sx={{ p: 3 }}>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </Box>
        <CardActions
          sx={{ bottom: 0, display: 'flex', justifyContent: 'center', mb: 2 }}
        >
          <Button
            size="medium"
            variant="contained"
            sx={{
              borderRadius: 6,
              pl: 6,
              pr: 6,
              pt: 1.25,
              pb: 1.25,
            }}
          >
            Learn more
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
