import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import Header from './components/Header/Header';
import Home from './routes/Home/Home';
import ProtectedRoute from './routes/ProtectedRoute/ProtectedRoute';
import PartnerNet from './routes/PartnerNet/PartnerNet';
import Campaign from './routes/Campaign/Campaign';
import NotFound from './routes/NotFound/NotFound';
import AuthProvider from './auth/authProvider';
import theme from './config/theme';
import cookieScript from './config/cookie';
import vaillantFavicon from './assets/vaillant-favicon.png';
import bulexFavicon from './assets/bulex-favicon.png';
import { isVaillant } from './helpers/realm';
import Popup from './routes/Popup/Popup';

function App() {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = isVaillant ? vaillantFavicon : bulexFavicon;

    document.head.appendChild(link);
    document.body.appendChild(cookieScript);
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Header />
          <Container maxWidth="xl" sx={{ pb: 3 }}>
            <Routes>
              <Route element={<Home />} path="/" />
              <Route
                element={
                  <ProtectedRoute>
                    <PartnerNet />
                  </ProtectedRoute>
                }
                path="/partnernet"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <Campaign />
                  </ProtectedRoute>
                }
                path="/campaign"
              />
              <Route
                element={
                  <Popup />
                }
                path="/popup"
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
