import { useContext, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import AuthContext from '../../auth/authContext';

export default function ProtectedRoute({ children }: { children: ReactNode }) {
  const { status, keycloak } = useContext(AuthContext);

  if (status.type === 'loading') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (status.type === 'initialized' && !keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  if (status.type === 'error') {
    return (
      <>
        <Typography variant="h4" mt={12} textAlign="center">
          There was an error, please try to refresh the page.
        </Typography>
        <Typography variant="h5" mt={2} textAlign="center">
          {status.message}
        </Typography>
      </>
    );
  }

  return <>{children}</>;
}
