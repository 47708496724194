import { createContext } from 'react';
import { AuthProviderType } from './types';
import keycloakInstance from '../config/auth';

const AuthContext = createContext<AuthProviderType>({
  status: { type: 'loading' },
  keycloak: keycloakInstance,
});

export default AuthContext;
