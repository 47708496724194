import { useEffect, useState, ReactNode } from 'react';

import AuthContext from './authContext';
import keycloak from '../config/auth';
import { StatusType } from './types';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<StatusType>({ type: 'loading' });

  useEffect(() => {
    keycloak
      .init({
        pkceMethod: 'S256',
        onLoad: 'check-sso',
        checkLoginIframe: false,
        redirectUri: window.location.origin
      })
      .then((authenticated) => {
        setStatus({ type: 'initialized' });

        if (authenticated) {
          keycloak
            .loadUserInfo()
            .then(() => {
              setStatus({ type: 'authorized' });
            })
            .catch((e) => {
              setStatus({ type: 'error', message: e?.toString() });
            });
        }
      })
      .catch((e) => {
        setStatus({ type: 'error', message: e?.toString() });
      });
  }, []);

  return (
    <AuthContext.Provider value={{ status, keycloak }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
