import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExternalCardLinkProps from './ExternalCardLink.types';
import styles from './ExternalCardLink.module.css';

export default function ExternalCardLink({
  title,
  description,
  href,
}: ExternalCardLinkProps) {
  return (
    <Paper sx={{ p: 3, position: 'relative' }}>
      <Link href={href} underline="hover" className={styles.link}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
      </Link>
      <Typography gutterBottom variant="body1">
        {description}
        <>&nbsp;&gt;</>
      </Typography>
    </Paper>
  );
}
