import { ChangeEvent, useEffect, useState, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AuthContext from '../../auth/authContext';

import { UploaderStatusType } from './Campaign.types';
import formatBytes from '../../helpers/formatBytes';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const uploadUrl = new URL(
    '/api/files/upload?code=TyNuQAMypsupm1Qw5LrYOnopOuNQoglBjhiLKGmEpJSFAzFuSUUojg==',
    'https://dsp-fileupload-qa.azurewebsites.net'
  )

const sizeLimit30Mb = 31457280;

type fileuploadResponse = {
  filename?: string,
  recordId?: string,
  contentVersionId?: string,
  contentDocumentId?: string,
  contentDocumentLinkId?: string,
}

function createData(
  filename?: string,
  recordId?: string,
  contentVersionId?: string,
  contentDocumentId?: string,
  contentDocumentLinkId?: string,
) {
  return { filename, recordId, contentVersionId, contentDocumentId, contentDocumentLinkId };
}

export default function Campaign() {
  const [files, setFiles] = useState<File[]>([]);
  const [sizeError, setSizeError] = useState(false);
  const [status, setStatus] = useState<UploaderStatusType>({ type: 'idle' });
  const isLoading = status.type === 'loading';
  const { keycloak } = useContext(AuthContext);
  const [visible, setVisibility] = useState<boolean>(false);
  const [rows, setRows] = useState<fileuploadResponse[]>([])

  useEffect(() => {
    validateFileSize(files);

    if (!files.length && status.type === 'error') {
      setStatus({ type: 'idle' });
    }
  }, [files, status]);

  const handleAdd = async () => {
    setVisibility(false)
  }

  const sendFiles = async () => {
    if (!files.length) {
      return;
    }
    setStatus({ type: 'loading' });

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('*', file, file.name);
    });
    uploadUrl.searchParams.set("recordID", keycloak.userInfo?.salesforceContactId!);

    try {
      const res = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        setFiles([]);
        let jsonResponse = await res.json()
        setStatus({ type: 'success', message: 'File upload success'});
        let resRows = jsonResponse.map( (fileData: fileuploadResponse) => {
          console.log(fileData.filename)
          return createData(fileData.filename, fileData.recordId, fileData.contentVersionId, fileData.contentDocumentId, fileData.contentDocumentLinkId)
        })
        setRows(resRows)
        setVisibility(true);
      } else {
        setStatus({ type: 'error', message: res.statusText + ' ' + res.json() });
      }

    } catch {
      setStatus({
        type: 'error',
        message: 'Something went wrong, please try again',
      });
    }
  };

  const handleRemoveFile = (index: number) => {
    const copiedFiles = [...files];
    copiedFiles.splice(index, 1);

    setFiles(copiedFiles);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setStatus({ type: 'idle' });
    const fileArray = Array.from(e.target.files);

    setFiles(fileArray);
  };

  const validateFileSize = (fileArray: File[]) => {
    const sum = fileArray.reduce((prevValue, currentValue) => {
      return prevValue + currentValue.size;
    }, 0);

    if (sum > sizeLimit30Mb) {
      setSizeError(true);
    } else {
      setSizeError(false);
    }
  };

  return (
    <Stack spacing={3} pt={3}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">Upload files</Typography>
        <Stack spacing={2} direction="row" pt={2} pb={2}>
          <Button
            variant="contained"
            component="label"
            onClick={handleAdd}
            disabled={isLoading}
            endIcon={<UploadFileIcon />}
          >
            Add
            <input
              hidden
              multiple
              type="file"
              name="file"
              id="file"
              value=""
              onChange={(e) => handleInputChange(e)}
            />
          </Button>
          <Button
            variant="contained"
            component="label"
            onClick={sendFiles}
            disabled={!files.length || sizeError || isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          >
            Send<>&nbsp;</>
          </Button>
        </Stack>
        {sizeError ? (
          <Alert severity="warning">
            Files exceeded size limit ({formatBytes(sizeLimit30Mb)})
          </Alert>
        ) : null}
        {status.type !== 'idle' && status.type !== 'loading' ? (
          <Alert severity={status.type}>{status.message}</Alert>
        ) : null}
        {files.length ? (
          <Stack spacing={1} pt={2} divider={<Divider />}>
            {files.map((file, index) => (
              <Stack
                direction="row"
                key={file.name}
                alignItems="center"
                spacing={2}
              >
                <IconButton
                  size="small"
                  color="error"
                  aria-label="delete"
                  onClick={() => handleRemoveFile(index)}
                >
                  <DeleteIcon />
                </IconButton>
                <Typography>{file.name}</Typography>
                <Typography variant="body2" color="#999999">
                  ({formatBytes(file.size)})
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : null}
        <Stack>
          <TableContainer hidden={!visible} component={Paper}>
            <Table>
              <TableHead>
                <TableRow key='header'>
                  <TableCell align='right'>filename</TableCell>
                  <TableCell align='right'>recordID</TableCell>
                  <TableCell align='right'>contentVersionId</TableCell>
                  <TableCell align='right'>contentDocumentId</TableCell>
                  <TableCell align='right'>contentDocumentLinkId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: fileuploadResponse) => (
                  <TableRow
                    key={row.filename}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align='right'>
                      {row.filename}
                      </TableCell>
                    <TableCell align='right'>{row.recordId}</TableCell>
                    <TableCell align='right'>{row.contentVersionId}</TableCell>
                    <TableCell align='right'>{row.contentDocumentId}</TableCell>
                    <TableCell align='right'>{row.contentDocumentLinkId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
    </Stack>
  );
}
